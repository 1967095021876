import { SignatureCard } from "components/SignatureCard";
import { Container, StyledDiv } from "./Home.styles";

export const Home = ({ accessToken }: any) => {
  return (
    <Container>
      <StyledDiv>
        <SignatureCard accessToken={accessToken} />
      </StyledDiv>
    </Container>
  );
};
