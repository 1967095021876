import styled from "styled-components";

export const StyledTable = styled.table`
  background-color: white; 
  width: 360pt;
`;

export const StyledTr = styled.tr`
  height: 112.5pt;
`

export const UserDataTd = styled.td`
  width: 100%
`

export const StyledImg = styled.img`
  width: 382.99pt;
  height: 33.59pt
`;

