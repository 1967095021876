import styled from "styled-components";

export const StyledName = styled.p`
  font-size: 17pt;
  color: black;
  font-weight: bold;
`;

export const StyledJobTitle = styled.p`
  font-size: 12pt;
  margin-top: 1pt;
  color: black;
`;

export const StyledPhone = styled.p`
  font-size: 9pt;
  margin-top: 1pt;
  color: black;
`;

export const StyledLink = styled.p`
  font-size: 9pt;
  margin-top: 1pt;
  font-weight: bold;
  a {
    color: blue;
  }
`;
