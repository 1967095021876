import { useIsAuthenticated } from "@azure/msal-react";
import { Header } from "components/Header";
import { NavBar } from "components/Navbar";
import { AccessDenied } from "pages/AccessDenied/AccessDenied";
import { Home } from "pages/Home";
import { NotFound } from "pages/NotFound";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export function MainRouter({ RequestAccessToken }: any) {
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const isAuthenticated = useIsAuthenticated();
  
  useEffect(() => {
    if (isAuthenticated && accessToken === null) {
      RequestAccessToken(setAccessToken);
    }
  }, [accessToken, RequestAccessToken, isAuthenticated]);
  
  return (
    <BrowserRouter>
      <Header />
      <NavBar />
      <Routes>
        <Route path="/" element={accessToken ? <Home accessToken={accessToken} /> : <AccessDenied />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
