import styled from "styled-components";

export const StyledLabel = styled.label`
  display: block;
  padding-right: 10px;
  padding-left: 22px;
  text-indent: -22px;
  margin-bottom: 5pt;
`;

export const StyledInput = styled.input`
  vertical-align: middle;
`;

export const StyledText = styled.strong`
  vertical-align: middle;
  margin-left: 5pt;
`;
