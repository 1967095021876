import { StyledTr } from "./SignatureBlueBar.styles";

export const SignatureBlueBar = () => {
  return (
    <table>
      <tbody>
        <StyledTr>
          {Array(6)
            .fill("")
            .map((elem, index) => {
              return (
                <span key={index}>
                  &nbsp;
                  <br />
                </span>
              );
            })}
        </StyledTr>
      </tbody>
    </table>
  );
};
