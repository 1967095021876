import styled from "styled-components";

export const StyledP = styled.p`
  text-align: center;
  margin: 5pt auto;
  margin-top: 15pt;
  color: #777;
`;

export const StyledImg = styled.img`
  width: 382.99pt;
  height: 30pt;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 15px;
  padding: 8px 12px;
  border-radius: 3px;
  margin: 0px 15px;
  cursor: pointer;
  box-shadow: 2px 2px #CCC;
  &:hover:enabled {
    opacity: 0.8;
  }
  &:active:enabled {
    opacity: 0.4;
    box-shadow: -2px -2px #CCC;
  }
  &:disabled {
    background-color: #555;
    border: 1px solid #555;
    opacity: 0.5;
    cursor: default;
  }
`

export const StyledBlueButton = styled(StyledButton)`
  background-color: #0054AF;
  border: 1px solid #0054AF;
`

export const StyledGreenButton = styled(StyledButton)`
  background-color: #47B541;
  border: 1px solid #47B541;

`

export const StyledRedButton = styled(StyledButton)`
  background-color: #CA3F43;
  border: 1px solid #CA3F43;

`
