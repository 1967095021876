import { StyledImg } from "./SignatureIcon.styles";

export const SignatureIcon = ({ ...props }) => {
  return (
    <td>
      <a href={props.url} rel="noopener noreferrer">
        <StyledImg src={props.img} alt={props.alt} />
      </a>
    </td>
  );
};
