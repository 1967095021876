import {
  StyledJobTitle,
  StyledLink,
  StyledName,
  StyledPhone,
} from "./UserData.styles";

export const UserData = ({ ...props }) => {
  return (
    <span>
        <StyledName>{props.givenName + " " + props.surname}</StyledName>
        <StyledJobTitle>{props.jobTitle}</StyledJobTitle>
        <StyledPhone>{props.phone}</StyledPhone>
        <StyledLink>
          <a href="https://teltecsolutions.com.br/" rel="noopener noreferrer">
            teltecsolutions.com.br
          </a>
        </StyledLink>
    </span>
  );
};
