import { useEffect, useState } from "react";
import { userData } from "../../services/apiData";
import { SignatureBlueBar } from "../SignatureBlueBar";
import { SignatureIcons } from "../SignatureIcons";
import { ProfilePicture } from "../ProfilePicture";
import { UserData } from "../UserData";
import { SignatureBanner } from "../SignatureBanner";
import { StyledTr, StyledTable, UserDataTd } from "./SignatureCard.styles";

export const SignatureCard = ({ accessToken }: any) => {
  const initState = {
    givenName: "",
    jobTitle: "",
    displayName: "",
    surname: "",
    mobilePhone: "",
    businessPhones: 0,
    mail: "",
  };

  const [info, setInfos] = useState(initState);
  const [customPhone, setCustomPhone] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  useEffect(() => {
    if (accessToken) {
      userData(accessToken).then((res) => {
        const data = {
          givenName: res.givenName,
          jobTitle: res.jobTitle,
          displayName: `${res.givenName} ${res.surname}`,
          surname: res.surname,
          mobilePhone: res.mobilePhone,
          businessPhones: res.businessPhones[0],
          mail: res.mail,
        };
        setInfos(data);
        setDataLoading(false);
      });
    }
  }, [accessToken]);

  return dataLoading === true ? (
    <p>loading...</p>
  ) : (
    <div>
      <StyledTable>
        <tbody>
          <StyledTr>
            <td>
              <ProfilePicture info={info} accessToken={accessToken} />
            </td>
            <UserDataTd>
              <UserData
                givenName={info.givenName}
                surname={info.surname}
                jobTitle={info.jobTitle}
                phone={customPhone === "" ? info.businessPhones : customPhone}
              />
            </UserDataTd>
            <td>
              <SignatureBlueBar />
            </td>
            <td>
              <SignatureIcons />
            </td>
          </StyledTr>
        </tbody>
      </StyledTable>
      <SignatureBanner customPhone={customPhone} setCustomPhone={setCustomPhone} />
    </div>
  );
};
