import axios from "axios";
import axiosRetry from "axios-retry";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_MS_GRAPH_URL}`,
});

axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

export const userData = async (accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get("/", { headers: headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};
