import { useState } from "react";
import { StyledP, StyledImg, StyledBlueButton, StyledGreenButton, StyledRedButton } from "./SignatureBanner.styles";
import BannerTeltec from "assets/bannerTeltec.png"
import BannerServiceDesk from "assets/bannerServiceDesk.png";
import BannerSoc from "assets/bannerSoc.png";
import { ToggleInput } from "components/ToggleInput";

export const SignatureBanner = ({ ...props }) => {
  const [banner, setBanner] = useState<string>(BannerTeltec);

  const handleChange = (event: any) => {
    props.setCustomPhone(event.target.value);
  };

  return (
    <span>
      <a
        href="https://teltecsolutions.com.br/quem-somos/"
        rel="noopener noreferrer"
      >
        <StyledImg src={banner} />
      </a>
      <StyledP>
        <StyledBlueButton onClick={ () => setBanner(BannerTeltec) }>Banner Teltec</StyledBlueButton>
        <StyledGreenButton onClick={ () => setBanner(BannerServiceDesk) }>Banner Service Desk</StyledGreenButton>
        <StyledRedButton onClick={ () => setBanner(BannerSoc) }>Banner SOC</StyledRedButton>
      </StyledP>
      <ToggleInput type={"tel"} placeholder={"Digite o número do telefone"} value={props.customPhone} onChange={handleChange}/>
    </span>
  );
};
