import axios, { AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BLOBSTORAGE_URL}`,
});

axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

export const profilePicture = async (accessToken: string, email: string) => {
  const config: AxiosRequestConfig<any> = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return instance
    .get(`/retrieve-blob-profile-picture?email=${email}`, config)
    .then((response) => {
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return base64;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

