import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20pt;
`;

export const StyledDiv = styled.div`
  padding: 15pt;
  border-radius: 5pt;
  border: 1pt solid rgba(3, 3, 3, 0.2);
  background-color: white;
`;
