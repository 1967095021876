import { StyledInput, Container } from "./ToggleInput.styles";
import { useState } from "react";
import { ToggleButton } from "components/ToggleButton";

export const ToggleInput = ({ ...props }) => {
  const [toggle, setToggle] = useState<boolean>(true);
  
  return (
    <Container>
      <ToggleButton
        label="ATENÇÃO: Insira o número do telefone"
        toggled={toggle}
        onClick={setToggle}
      />
      {toggle ? <StyledInput {...props} /> : ""}
    </Container>
  );
};
