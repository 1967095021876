import Linkedin from "assets/linkedin2.png";
import Instagram from "assets/instagram.png";
import Facebook from "assets/facebook.png";
import Teltec from "assets/teltecsolutions.png";
import { SignatureIcon } from "../SignatureIcon/SignatureIcon";
import { StyledTr } from "./SignatureIcons.styles";

export const SignatureIcons = () => {
  return (
    <table>
      <tbody>
        <StyledTr>
          <SignatureIcon
            alt="Teltec Solutions Linkedin"
            url="https://www.linkedin.com/company/teltec-solutions"
            img={Linkedin}
          ></SignatureIcon>
        </StyledTr>
        <StyledTr>
          <SignatureIcon
            alt="Teltec Solutions Facebook"
            url="https://www.facebook.com/teltecsolutions"
            img={Facebook}
          ></SignatureIcon>
        </StyledTr>
        <StyledTr>
          <SignatureIcon
            alt="Teltec Solutions Instagram"
            url="https://www.instagram.com/teltecsolutions"
            img={Instagram}
          ></SignatureIcon>
        </StyledTr>
        <StyledTr>
          <SignatureIcon
            alt="Teltec Solutions Blog"
            url="https://teltecsolutions.com.br/blog"
            img={Teltec}
          ></SignatureIcon>
        </StyledTr>
      </tbody>
    </table>
  );
};
