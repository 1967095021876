import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  text-align: left;
  margin: 5pt auto;
  margin-top: 15px;
  /* color: #777; */
  color: red;
`;

export const StyledInput = styled.input`
  width: 100%;
`;

export const StyledToggle = styled.input`
  margin-right: 15pt;
`;
