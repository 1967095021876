import { useCallback, useEffect, useState } from "react";
import { profilePicture } from "services/apiPhoto";
import { StyledDiv } from "./ProfilePicture.styles";

export const ProfilePicture = ({ ...props }) => {
  const [image, setImage] = useState("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const mailphoto = useCallback(
    async (info: any) => {
      if (props.accessToken) {
        profilePicture(props.accessToken, info.mail)
          .then((response) => {
            setImage(response);
            setDataLoading(false);
          })
          .catch(() => {
            setDataLoading(false);
            setError(true);
          });
      }
    },
    [props.accessToken]
  );

  useEffect(() => {
    if (props.info.mail !== "") {
      mailphoto(props.info);
    }
  }, [props.info, mailphoto]);

  return dataLoading === true ? (
    <StyledDiv>
      <p>loading...</p>
    </StyledDiv>
  ) : error ? (
    <StyledDiv>
      <p>Erro ao carregar foto</p>
    </StyledDiv>
  ) : (
    <span>
      {props.image !== "" && (
        <img src={`data:image/jpeg;base64,${image}`} alt="Employee profile" />
      )}
    </span>
  );
};
