import { AuthButton } from "components/AuthButton";
import { Container, Image } from "./Header.styles";
import teltecLogo from "assets/logo-primary.png";

export const Header = () => {
  return (
    <Container>
      <Image src={teltecLogo} className="Logo" alt="logo"/>
      <AuthButton />
    </Container>
  );
};
